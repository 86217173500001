<template>
  <div class="input-rate">
    <input type="text" v-model="inputValue" @focus="selectAll" @input="onInput" />
    <div class="input-rate-endfix">개월</div>
  </div>
</template>
<script>
export default {
  name: 'InputPeriod',
  components: {},
  mixins: [],
  props: {
    value: {
      type: [Number, String],
      default: '0',
    },
  },
  data() {
    return {
      inputValue: '0',
    };
  },
  computed: {},
  watch: {},
  created() {
    this.inputValue = this.value.toString();
  },
  mounted() {},
  methods: {
    onInput(e) {
      this.inputValue = e.target.value.replace(/\D/g, '').substring(0, 3);
      this.$emit('update:value', this.inputValue);
    },
    selectAll(e) {
      e.target.select();
    },
  },
};
</script>
<style lang="stylus" scoped>
.input-rate
    position relative
    max-width 240px
input
  text-align right
  padding-right 32px
.input-rate-endfix
  position absolute
  font-size 15px
  right 16px
  top 50%
  transform translateY(-50%)
  color #333
@media (max-width: 768px)
  .input-rate
    max-width 100%
</style>
